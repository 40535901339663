const news = [
  {
    id: 1,
    name: 'onboardingDigital',
    title: 'onboardingDigital.title',
    content: 'onboardingDigital.content',
    src: 'mujer.jpg',
    date: '01-11-2019',
    industries: ['bank'],
    urlVideo: 'assets/videos/news/onboarding-digital.mp4',
  },
  {
    id: 2,
    name: 'microAnalyzer',
    title: 'microAnalyzer.title',
    content: 'microAnalyzer.content',
    src: 'cientifico.jpg',
    date: '15-03-2019',
    industries: ['health'],
    urlVideo: 'assets/videos/news/micro-analyzer.mp4',
  },
  {
    id: 3,
    name: 'covid19',
    title: 'covid19.title',
    content: 'covid19.content',
    src: 'celular.jpg',
    date: '15-03-2020',
    industries: ['health', 'retail', 'education'],
    urlVideo: 'assets/videos/news/covid19.mp4',
  },
  {
    id: 4,
    name: 'd24',
    title: 'd24.title',
    content: 'd24.content',
    src: 'paisaje.jpg',
    date: '19-04-2019',
    industries: ['transport', 'education'],
    urlVideo: 'assets/videos/news/d24.mp4',
  },
];

export default news;
