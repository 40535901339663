export const titleCase = (string) => {
  let sentence = string.toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
};

export const upperCaseFirstWord = (string) => {
  let sentence = string.toLowerCase().split(' ');
  sentence[0] = sentence[0][0].toUpperCase() + sentence[0].slice(1);
  return sentence.join(' ');
};
