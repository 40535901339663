import { useState, useEffect } from 'react';

const useSlider = (ref, translateSize) => {
  const [y, setY] = useState(0);
  const [offSet, setOffset] = useState(0);

  const goPrev = () => {
    setY((curr) => curr + translateSize);
  };

  const goNext = () => {
    setY((curr) => curr - translateSize);
  };

  useEffect(() => {
    const totalWidth = ref.current.scrollWidth;
    const visibleWidth = ref.current.clientWidth;
    setOffset(totalWidth - visibleWidth);
    ref.current.style.left = `${y}px`;
  }, [y, ref]);

  return { y, offSet, goPrev, goNext };
};

export default useSlider;
