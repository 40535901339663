import { upperCaseFirstWord } from 'helpers/strings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './styles.css';

const SideContent = ({ visible }) => {
  const { t } = useTranslation(['rest']);
  const history = useHistory();

  const handleLink = () => {
    history.push('/solutions');
    window.scrollTo(0, 0);
  };
  return (
    <div id="solutions-sideContent">
      <div id="sideContent-text-container" className={visible ? 'animated' : undefined}>
        <span className="sideContent-secondary">{upperCaseFirstWord(t('words.toKnow'))}</span>
        <span className="sideContent-main">{t('words.allOurSolutions')}</span>
      </div>
      <div
        id="sidecontent-button"
        className={visible ? 'animated' : undefined}
        onClick={handleLink}
      >
        {t('words.goHere')}
      </div>
    </div>
  );
};

SideContent.propTypes = {
  visible: PropTypes.bool,
};

export default SideContent;
