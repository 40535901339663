import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './styles.css';

const Footer = () => {
  const { t } = useTranslation(['rest']);
  const history = useHistory();

  const handleLink = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="footer-section-container">
        <div className="footer-logo-container">
          <img width="270" src="assets/img/logo.svg" />
        </div>
        <div className="footer-links-container">
          <div className="footer-links-first-column">
            <div className="footer-link-container">
              <a className="navbar-link" onClick={() => handleLink('/about-us')}>
                {t('words.aboutUs')}
              </a>
            </div>
            <div className="footer-link-container">
              <a className="navbar-link" onClick={() => handleLink('/solutions')}>
                {t('words.solutions').toUpperCase()}
              </a>
            </div>
          </div>
          <div className="footer-links-second-column">
            <div className="footer-link-container">
              <a className="navbar-link" onClick={() => handleLink('/contact-us')}>
                {t('words.contactUs')}
              </a>
            </div>
            <div className="footer-link-container">
              <a className="navbar-link" onClick={() => handleLink('/news')}>
                {t('words.news')}
              </a>
            </div>
          </div>
        </div>
        <div className="footer-social-container">
          <span id="footer-social-text"> {t('words.followUs')}</span>
          <div className="footer-social-icons-container">
            <a href="https://www.linkedin.com/company/entreconsultas/mycompany/">
              <img className="footer-social-icon" src="assets/img/linkedin.svg" />
            </a>
            <a href="https://www.instagram.com/entre.consultas/">
              <img className="footer-social-icon" src="assets/img/instagram.svg" />
            </a>
            <a href="https://twitter.com/EntreConsultas">
              <img className="footer-social-icon" src="assets/img/twitter.svg" />
            </a>
          </div>
        </div>
      </div>
      <div id="footer-bottom-segment" />
    </>
  );
};

export default Footer;
