import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import './styles.css';
import { useHistory } from 'react-router-dom';
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';

const Menu = ({ handleCloseMenu }) => {
  const { t } = useTranslation(['rest']);
  const history = useHistory();

  const handleLink = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <div className="navbar-menu-mobile-container">
      <ClearIcon className="navbar-close-icon" fontSize="large" onClick={handleCloseMenu} />
      <div onClick={handleCloseMenu} className="navbar-link-container">
        <span className="navbar-link navbar-link-mobile" onClick={() => handleLink('/about-us')}>
          {t('words.aboutUs')}
        </span>
        <span className="navbar-link navbar-link-mobile" onClick={() => handleLink('/solutions')}>
          {t('words.solutions').toUpperCase()}
        </span>
        <span className="navbar-link navbar-link-mobile" onClick={() => handleLink('/contact-us')}>
          {t('words.contactUs')}
        </span>
        <span className="navbar-link navbar-link-mobile" onClick={() => handleLink('/news')}>
          {t('words.news')}
        </span>
        <SelectLanguage />
      </div>
    </div>
  );
};

export default Menu;

Menu.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
};
