import { useTranslation } from 'react-i18next';
// import { MenuItem, Select } from '@material-ui/core';
import BlueBanner from 'components/BlueBanner';
import NewsCard from 'components/NewsCard';
import Featured from './components/Featured';
import { news } from 'data';
import './styles.css';

const News = () => {
  const { t } = useTranslation(['news', 'rest', 'blueBanner']);
  const randomNum = Math.floor(Math.random() * news.length);
  const randomNew = news[randomNum];
  return (
    <>
      <div id="all-news-container-top">
        <span id="all-news-sub">{t('rest:words.news')}</span>
        <span id="all-news-tit">{t('rest:words.featured')}</span>
        <Featured {...randomNew} />
      </div>
      <div id="all-news-container-bottom">
        <div id="all-news-heading">
          <span id="all-news-heading-title">{t('rest:words.allNews')}</span>
          {/* <Select value="industries">
            <MenuItem value="health">
              <div>ind</div>
            </MenuItem>
          </Select> */}
        </div>
        <div id="all-news-grid-container">
          {news.map((item) => (
            <NewsCard key={item.id} style="column" {...item} />
          ))}
        </div>
      </div>
      <div id="news-bluecontainer">
        <BlueBanner
          title={t('blueBanner:option2.title')}
          buttonLabel={t('blueBanner:option2.button')}
          titleStyle={{ fontSize: '32px', maxWidth: '400px' }}
        />
      </div>
    </>
  );
};

export default News;
