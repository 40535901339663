import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Collapsible from 'react-collapsible';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

const triggerWhenClosed = (title) => (
  <div className="trigger-custom-container">
    {title}
    <AddIcon />
  </div>
);

const triggerWhenOpen = (title) => (
  <div className="trigger-custom-container">
    {title}
    <CloseIcon />
  </div>
);

const Accordion = ({ items }) => {
  return items.map((item, index) => (
    <Collapsible
      transitionTime={100}
      key={index}
      trigger={triggerWhenClosed(item.title)}
      triggerWhenOpen={triggerWhenOpen(item.title)}
      className="trigger-closed"
      openedClassName="trigger-open"
      contentInnerClassName="trigger-content"
    >
      <p>{item.description}</p>
    </Collapsible>
  ));
};

export default Accordion;

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
};
