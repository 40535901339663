import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import AboutUs from './pages/about-us';
import Home from './pages/home';
import Solutions from './pages/solutions';
import ContactUs from './pages/contact-us';
import Startup from './pages/wc';
// import Blog from './pages/blog';
import News from './pages/news';
import SingleNew from 'pages/SingleNew';
import SingleSolution from './pages/SingleSolution';
import './App.css';

export default function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/solutions/:id">
          <SingleSolution />
        </Route>
        <Route path="/solutions">
          <Solutions />
        </Route>
        <Route path="/news/:id">
          <SingleNew />
        </Route>
        <Route path="/news">
          <News />
        </Route>
        <Route path="/contact-us">
          <ContactUs />
        </Route>
        <Route path="/wc/startup_presentation_001">
          <Startup />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
