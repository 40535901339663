import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LittleCard from './LittleCard';
import './index.css';

const Optimization = () => {
  const { t } = useTranslation(['littleCards']);
  return (
    <div className="au-o-container">
      <Grid container>
        <Grid item sm={12} md={8} lg={4}>
          <div className="au-o-text-container">
            <p id="au-o-caption">{t('aboutUs.subtitle')}</p>
            <p id="au-o-title">{t('aboutUs.title')}</p>
            <p id="au-o-description">{t('aboutUs.description')}</p>
          </div>
        </Grid>
        <div className="au-o-card-container">
          <LittleCard id="fast" title={t('fast.title')} description={t('fast.description')} />
          <LittleCard
            id="efficient"
            title={t('efficient.title')}
            description={t('efficient.description')}
          />
          <LittleCard
            id="targeted"
            title={t('targeted.title')}
            description={t('targeted.description')}
          />
        </div>
      </Grid>
    </div>
  );
};

export default Optimization;
