import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.css';
import { useHistory } from 'react-router-dom';

const NewsCard = ({ id, title, src, style, industries, date }) => {
  const { t } = useTranslation(['news', 'industries']);
  const history = useHistory();
  if (!title) return null;

  const handleLink = (id) => {
    history.push(`/news/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div
      id="single-news-card-container"
      className={style === 'column' ? 'card-container-column' : 'card-container'}
      onClick={() => handleLink(id)}
    >
      <div className="news-tag-container">
        {!!industries.length &&
          industries.map((ind, index) => (
            <span key={index.toString()} className="news-card-tag">
              {`${t(`industries:${ind}`)}${industries.length !== index + 1 ? '/' : ''}`}
            </span>
          ))}
      </div>
      <h4 className="card-title">{t(title)}</h4>
      <span className="news-card-date">{date}</span>
      <div className="card-image">{src && <img src={`assets/img/${src}`} />}</div>
    </div>
  );
};

NewsCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  style: PropTypes.string,
  industries: PropTypes.array,
};
export default NewsCard;
