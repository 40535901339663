import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.css';
import useWindowSize from 'hooks/useWindowsSize';
import { IconButton, makeStyles } from '@material-ui/core';
import SelectLanguage from './SelectLanguage';
import Menu from './Menu';

const useStyles = makeStyles({
  root: {
    background: '#15B2DB',
    height: '50px',
    width: '50px',
    borderRadius: '100%',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25);',
    position: 'absolute',
    right: '25px',
    top: '20px',
  },
});

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowSize();
  const [scrollY, setScrollY] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const { t } = useTranslation(['rest']);

  const { pathname } = useLocation();

  const dark = pathname !== '/';

  const isMobile = () => size.width <= 1000;

  useEffect(() => {
    window.onscroll = () => {
      setScrollY(window.pageYOffset);
    };

    return () => (window.onscroll = null);
  }, []);

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleLink = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return openMenu ? (
    <Menu handleCloseMenu={handleCloseMenu} />
  ) : (
    <div
      className={`navbar-header-container ${scrollY >= 200 || dark ? 'navbar-header-dark' : ''} ${
        isMobile() ? 'navbar-header-mobile' : ''
      }`}
    >
      <div className="navbar-container">
        <div className="navbar-logo-container">
          <img
            className="navbar-image-logo"
            src={isMobile() ? 'assets/img/logo.svg' : 'assets/img/logo-white.svg'}
            onClick={() => history.push('/')}
          />
          <span
            className={`navbar-logo-text ${isMobile() ? 'navbar-logo-text-mobile' : undefined}`}
          >
            Computer vision + Artificial intelligence{' '}
          </span>
        </div>
        {!isMobile() ? (
          <div style={{ marginRight: '30px' }}>
            <span className="navbar-link" onClick={() => handleLink('/about-us')}>
              {t('words.aboutUs')}
            </span>
            <span className="navbar-link" onClick={() => handleLink('/solutions')}>
              {t('words.solutions').toUpperCase()}
            </span>
            <span className="navbar-link" onClick={() => handleLink('/contact-us')}>
              {t('words.contactUs')}
            </span>
            <span className="navbar-link" onClick={() => handleLink('/news')}>
              {t('words.news')}
            </span>
            <SelectLanguage />
          </div>
        ) : (
          <div onClick={() => setOpenMenu(true)}>
            <IconButton className={classes.root}>
              <img src="assets/img/hamburger-button.svg" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;

Navbar.propTypes = {
  dark: PropTypes.bool,
};

Navbar.defaultProps = {
  dark: false,
};
