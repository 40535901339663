import React from 'react';
import './index.css';
import CardItem from './CardItem';
import { useState } from 'react';
import { solutions } from 'data';
import FilterSection from './FilterSection';

const CardList = () => {
  const [filter, setFilter] = useState('all');
  const [items, setItems] = useState(solutions);

  const handleChangeFilter = (name) => {
    setFilter(name);
    let _items = solutions;
    if (name !== 'all') _items = solutions.filter((item) => item.industry.includes(name));
    setItems(_items);
  };

  return (
    <div className="section-tp-container">
      <FilterSection filter={filter} handleChangeFilter={handleChangeFilter} />
      <div className="section-tp-absolute">
        <div className="section-tp-card-container">
          {items.map((item) => (
            <CardItem key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardList;
