import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { useHistory } from 'react-router-dom';

const BlueBanner = ({ caption, title, buttonLabel, titleStyle }) => {
  const history = useHistory();

  const handleLink = () => {
    history.push('/contact-us');
    window.scrollTo(0, 0);
  };

  return (
    <div className="blue-banner-section">
      <div className="blue-banner-text">
        {caption && <span id="blue-banner-caption">{caption}</span>}

        <span id="blue-banner-title" style={titleStyle}>
          {title}
        </span>
      </div>
      <button id="blue-banner-button" onClick={handleLink}>
        <a>{buttonLabel}</a>
      </button>
    </div>
  );
};

export default BlueBanner;

BlueBanner.propTypes = {
  caption: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
};

BlueBanner.defaultProps = {
  caption: '',
  titleStyle: {},
};
