import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './index.css';

const CardItem = ({ icon, title, resume, id }) => {
  const { t } = useTranslation(['solutions', 'rest']);
  const history = useHistory();

  const handleClick = (id) => {
    window.scrollTo(0, 0);
    history.push(`/solutions/${id}`);
  };

  return (
    <div className="section-cl-card-container">
      <div className="section-cl-card">
        <div className="section-cl-card-top">
          <img src={icon} />
        </div>
        <div className="section-cl-card-bottom">
          <span id="section-cl-card-title">{t(title)}</span>
          <div id="section-cl-card-description-container">
            <p id="section-cl-card-description">{t(resume)}</p>
          </div>
          <div className="section-cl-card-icon-container">
            <ArrowRightAlt onClick={() => handleClick(id)} id="section-cl-card-icon" />
          </div>
          <div className="section-cl-card-button-container">
            <div id="section-cl-card-button-hover" onClick={() => handleClick(id)}>
              <ArrowRightAlt id="section-cl-card-icon-hover" />{' '}
              <span id="section-cl-card-button-text">{t('rest:words.more')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItem;

CardItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  resume: PropTypes.string,
  id: PropTypes.number,
};
