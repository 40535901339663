import React from 'react';
import { useTranslation } from 'react-i18next';
import Optimization from './components/Optimization';
import OurMethodology from './components/OurMethodology';
import TopSection from './components/TopSection';
import WhatWeDo from './components/WhatWeDo';
import BlueBanner from '../../components/BlueBanner';
import Solutions from './components/Solutions';

const AboutUs = () => {
  const { t } = useTranslation(['blueBanner']);
  return (
    <>
      <TopSection />
      <WhatWeDo />
      <Optimization />
      <OurMethodology />
      <BlueBanner
        title={t('option2.title')}
        buttonLabel={t('option2.button')}
        titleStyle={{ fontSize: '32px', maxWidth: '400px' }}
      />
      <Solutions />
    </>
  );
};

export default AboutUs;
