import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useSlider from 'hooks/useSlider';
import NewsCard from 'components/NewsCard/NewsCard';
import { news } from 'data';
import './styles.css';

const News = ({ color }) => {
  const { t } = useTranslation(['rest']);
  const ref = useRef(null);
  const { y, offSet, goPrev, goNext } = useSlider(ref, 280);

  return (
    <div id="news-container" className={`nc-${color}`}>
      <h3 className={`news-title nc-${color}`}>{t('rest:words.latestNews')}</h3>
      <div className="news-arrow_container">
        <button className="news-arrow" disabled={y === 0} onClick={goPrev}>
          <div className="icon left"></div>
        </button>
        <button className="news-arrow" disabled={y < -offSet} onClick={goNext}>
          <div className="icon right"></div>
        </button>
      </div>
      <div className="news-swiper">
        <div ref={ref} className="cards-container">
          {news.map((card, index) => (
            <NewsCard key={String(index)} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;

News.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
};
News.defaultProps = {
  color: 'dark',
};
