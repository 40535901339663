const solutions = [
  {
    id: 1,
    name: 'digitalOnboarding',
    title: 'digitalOnboarding.title',
    subTitle: 'digitalOnboarding.subtitle',
    resume: 'digitalOnboarding.resume',
    description: 'digitalOnboarding.description',
    industry: ['bank'],
    icon: 'assets/img/digital-onboarding-document.svg',
    video: 'assets/videos/solutions/onboarding-digital.mp4',
    image: 'assets/img/mujer.jpg',
  },
  {
    id: 2,
    name: 'microAnalyzer',
    title: 'microAnalyzer.title',
    subTitle: 'microAnalyzer.subtitle',
    resume: 'microAnalyzer.resume',
    description: 'microAnalyzer.description',
    industry: ['health'],
    icon: 'assets/img/microscope.svg',
    video: 'assets/videos/solutions/micro-analyzer.mp4',
    image: 'assets/img/cientifico.jpg',
  },
  {
    id: 3,
    name: 'covid19',
    title: 'covid19.title',
    subTitle: 'covid19.subtitle',
    resume: 'covid19.resume',
    description: 'covid19.description',
    industry: ['health'],
    icon: 'assets/img/covid-19.svg',
    video: 'assets/videos/solutions/covid19.mp4',
    image: 'assets/img/cientifico.jpg',
  },
  {
    id: 4,
    name: 'd24',
    title: 'd24.title',
    subTitle: 'd24.subtitle',
    resume: 'd24.resume',
    description: 'd24.description',
    industry: ['transport'],
    icon: 'assets/img/owl.svg',
    video: 'assets/videos/solutions/d24.mp4',
    image: 'assets/img/celular.jpg',
  },
  {
    id: 5,
    name: 'crowdControl',
    title: 'crowdControl.title',
    subTitle: 'crowdControl.subtitle',
    resume: 'crowdControl.resume',
    description: 'crowdControl.description',
    industry: ['retail'],
    icon: 'assets/img/crowd.svg',
    video: 'assets/videos/solutions/crowd-control.mp4',
    image: 'assets/img/paisaje.jpg',
  },
];

export default solutions;
