import React from 'react';
import News from 'pages/home/components/News/News';
import Form from './components/Form/Form';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <Form />
      <News color="light" title={t('news.title')} />
    </>
  );
};

export default ContactUs;
