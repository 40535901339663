import { useState } from 'react';
import PropTypes from 'prop-types';
import CardController from '../VerticalCard/CardController';
import './index.css';
import { useTranslation } from 'react-i18next';

const SwiperSlide = ({ src, index, actual }) => {
  return (
    <div className={actual === index ? 'mySlides fade' : 'mySlides hidden'}>
      <img alt={src} src={`assets/img/${src}`} />
    </div>
  );
};

const MainSlider = () => {
  const { t } = useTranslation(['solutions', 'home']);

  const SLIDES = [
    {
      url: '/solutions/1',
      src: 'mujer.jpg',
      title: t('digitalOnboarding.title'),
      subtitle: t('digitalOnboarding.subtitle'),
    },
    {
      url: '/solutions/2',
      src: 'cientifico.jpg',
      title: t('microAnalyzer.title'),
      subtitle: t('microAnalyzer.subtitle'),
    },
    {
      url: '/solutions/4',
      src: 'paisaje.jpg',
      title: t('d24.title'),
      subtitle: t('d24.subtitle'),
    },
    {
      url: '/about-us',
      src: 'celular.jpg',
      title: t('home:slider.we.are.innovation.title'),
      subtitle: '',
    },
  ];

  const [current, setCurrent] = useState(0);
  const disableLeft = current === 0;
  const disableRight = current === SLIDES.length - 1;

  const goPrev = () => {
    if (current === 0) return setCurrent(SLIDES.length - 1);
    setCurrent(current - 1);
  };

  const goNext = () => {
    if (SLIDES.length - 1 === current) return setCurrent(0);
    setCurrent(current + 1);
  };

  return (
    <div id="main-swiper">
      <div className="slideshow-container">
        {SLIDES.map((item, index) => (
          <SwiperSlide key={String(index)} actual={current} index={index} src={item.src} />
        ))}
      </div>
      <CardController slides={SLIDES} index={current} />
      <div className="arrow_container">
        <button disabled={disableLeft} className="arrow left" onClick={goPrev}>
          <div className="icon left"></div>
        </button>
        <button disabled={disableRight} className="arrow right" onClick={goNext}>
          <div className="icon right"></div>
        </button>
      </div>
    </div>
  );
};

SwiperSlide.propTypes = {
  src: PropTypes.string,
  index: PropTypes.number,
  actual: PropTypes.number,
};

export default MainSlider;
