import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Captcha from 'react-numeric-captcha';
import { send } from 'emailjs-com';
import emailjs from '../../../../emailkey';
import './styles.css';
import './captcha.css';

const INITIAL_FORM = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  checked: false,
};

const Form = () => {
  const { t } = useTranslation(['contactUs', 'rest']);
  const [country, setCountry] = useState('ar');
  const [form, setForm] = useState(INITIAL_FORM);
  const [isCaptchaOk, setIsCaptchaOk] = useState(false);

  const handleSend = async () => {
    let isFormIncomplete = false;
    for (var key in form) {
      if (!form[key] && key !== 'checked') {
        isFormIncomplete = true;
        break;
      }
    }
    if (isFormIncomplete) return alert(t('rest:words.completeForm'));
    if (!form.checked) return alert(t('rest:words.acceptContactForm'));
    if (!isCaptchaOk) return alert(t('rest:words.completeCaptcha'));
    setForm(INITIAL_FORM);
    try {
      await send(emailjs.SERVICE_ID, emailjs.TEMPLATE_ID, form, emailjs.USER_ID);
      alert(t('rest:words.thanksContactForm'));
    } catch (error) {
      alert(t('rest:words.errorContactForm'));
    }
  };
  return (
    <div className={`cu-container cu-container-${country}`}>
      <img id="cu-map-image" src={`assets/img/maps-${country}-mobile.jpg`} />
      <div id="cu-card-container">
        <div className="cu-card-container">
          <div className="cu-country-container">
            <span
              onClick={() => setCountry('ar')}
              className={`cu-country-label ${country === 'ar' ? 'cu-country-label-active' : ''}`}
            >
              {t('argentina')}
            </span>
            <span
              onClick={() => setCountry('co')}
              className={`cu-country-label ${country === 'co' ? 'cu-country-label-active' : ''}`}
            >
              {t('colombia')}
            </span>
            <span
              onClick={() => setCountry('usa')}
              className={`cu-country-label ${country === 'usa' ? 'cu-country-label-active' : ''}`}
            >
              {t('unitedStates')}
            </span>
          </div>
          <div>
            <div className="cu-input-row-container">
              <div className="cu-input-container">
                <span className="cu-input-label">{t('name')}</span>
                <input
                  maxLength={25}
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  className="input"
                  type="text"
                />
              </div>
              <div className="cu-input-container">
                <span className="cu-input-label">{t('email')}</span>
                <input
                  maxLength={50}
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  className="input"
                  type="text"
                />
              </div>
            </div>
            <div className="cu-input-row-container">
              <div className="cu-input-container">
                <span className="cu-input-label">{t('phone')}</span>
                <input
                  maxLength={25}
                  value={form.phone}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  className="input"
                  type="text"
                />
              </div>
              <div className="cu-input-container">
                <span className="cu-input-label">{t('subject')}</span>
                <input
                  maxLength={25}
                  value={form.subject}
                  onChange={(e) => setForm({ ...form, subject: e.target.value })}
                  className="input"
                  type="text"
                />
              </div>
            </div>
            <div className="cu-text-area-row-container">
              <div className="cu-text-area-container">
                <span className="cu-input-label">{`${t('message')} ${
                  form.message.length
                }/220`}</span>
                <textarea
                  maxLength={220}
                  value={form.message}
                  onChange={(e) => setForm({ ...form, message: e.target.value })}
                  className="cu-text-area"
                  rows="6"
                />
              </div>
            </div>
            <div className="cu-checkbox-container">
              <input
                type="checkbox"
                checked={form.checked}
                onChange={(e) => setForm({ ...form, checked: e.target.checked })}
              />
              <span className="cu-checkbox-label">{t('checkboxLabel')}</span>
            </div>
            <div className="captcha-container">
              <Captcha
                onChange={(status) => setIsCaptchaOk(status)}
                className="captcha"
                placeholder={t('rest:words.captchaPlaceholder')}
              />
            </div>
            <div className="cu-checkbox-container">
              <button id="cu-button" onClick={handleSend}>
                <a>{t('send')}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
