import { upperCaseFirstWord } from 'helpers/strings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

const TopSection = () => {
  const { t } = useTranslation(['rest', 'aboutUs']);
  return (
    <div className="au-ts-container">
      <img id="au-ts-logo" src="assets/img/about-us-ts-cut-logo.svg" />
      <img id="au-ts-logo-mobile" src="assets/img/about-us-ts-cut-logo-mobile.svg" />
      <div className="au-ts-caption-container">
        <span id="au-ts-caption">{t('aboutUs:top.subtitle')}</span>
      </div>
      <div className="au-ts-text-container">
        <div className="au-ts-text-caption-container">
          <span id="au-ts-text-caption">{upperCaseFirstWord(t('words.aboutUs'))}</span>
        </div>
        <div className="au-ts-text-description-container">
          <p>{t('aboutUs:top.descriptionFirst')}</p>
          <p>
            <span id="au-ts-text-description-p2-highlight">{t('aboutUs:top.descriptionHigh')}</span>
            <br />
            <span>{t('aboutUs:top.descriptionLast')}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
