import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './index.css';
import Svg from './Svg/Svg';

const ITEMS = [
  {
    id: 4,
    icon: 'owl',
    title: 'd24.title',
    description: 'd24.subtitle',
    color: '#FFFFFF',
  },
  {
    id: 2,
    icon: 'microscope',
    title: 'microAnalyzer.title',
    description: 'microAnalyzer.subtitle',
    color: '#FFFFFF',
  },
  {
    id: 3,
    icon: 'covid19',
    title: 'covid19.title',
    description: 'covid19.subtitle',
    color: '#FFFFFF',
  },
];
const Solutions = () => {
  const { t } = useTranslation(['aboutUs', 'solutions', 'rest']);
  const history = useHistory();
  const [items, setItems] = useState(ITEMS);

  const handleMouseOver = (index) => {
    const _items = [...items];
    _items[index].color = '#225CD1';
    setItems(_items);
  };

  const handleMouseLeave = (index) => {
    const _items = [...items];
    _items[index].color = '#FFFFFF';
    setItems(_items);
  };

  const handleClick = (url) => {
    window.scrollTo(0, 0);
    history.push(url);
  };

  return (
    <div className="au-s-container">
      <div className="au-s-text-container">
        <div className="au-s-text">
          <p id="au-s-title">{t('solutions.title')}</p>
        </div>
        <div className="au-s-text au-s-button-container">
          <button id="au-s-button" onClick={() => handleClick('/solutions')}>
            <a>{t('solutions.button')}</a>
          </button>
        </div>
      </div>
      <div className="au-s-slider-container">
        {items.map((item, index) => (
          <div
            onMouseOver={() => handleMouseOver(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            key={index}
            className="au-s-card-container"
          >
            <Svg name={item.icon} color={item.color} />
            <span id="au-s-card-title">{t(`solutions:${item.title}`)}</span>
            <span id="au-s-card-description">{t(`solutions:${item.description}`)}</span>
            <div id="au-s-km-button-container" onClick={() => handleClick(`/solutions/${item.id}`)}>
              <span id="au-s-card-button">{t('rest:words.know')}</span>
              <span id="au-s-card-button-high">{t('rest:words.more')}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Solutions;
