import { useRef } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useSlider from 'hooks/useSlider';
import Card from '../Card';
import './styles.css';

const Slider = ({ cards, visible, title }) => {
  const isTabletLg = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const ref = useRef(null);

  const { y, offSet, goPrev, goNext } = useSlider(ref, 250);
  return (
    <div id="solutions-slider-container">
      <div id="solutions-header-container">
        <span id="solutions-title" className={visible ? 'animated' : undefined}>
          {title}
        </span>
        {isTabletLg && (
          <div className="solutions arrow_container">
            <button className="solutions-arrow" disabled={y === 0} onClick={goPrev}>
              <div className="icon left"></div>
            </button>
            <button className="solutions-arrow" disabled={y <= -offSet} onClick={goNext}>
              <div className="icon right"></div>
            </button>
          </div>
        )}
      </div>
      <div ref={ref} id="solution-cards-container">
        {cards.map((card, i) => (
          <Card key={String(i)} {...card} />
        ))}
      </div>
    </div>
  );
};

Slider.propTypes = {
  cards: PropTypes.array,
  visible: PropTypes.bool,
  title: PropTypes.string,
};

export default Slider;
