const industries = [
  { name: 'all' },
  { name: 'bank', icon: 'bank' },
  { name: 'health', icon: 'health' },
  { name: 'retail', icon: 'retail' },
  { name: 'education', icon: 'education' },
  { name: 'transport', icon: 'transport' },
];

export default industries;
