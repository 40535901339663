import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import LittleCard from './LittleCard';
import './styles.css';

const BottomSection = () => {
  const ref = useRef(null);

  const visible = useIntersectionObserver(ref);

  const { t } = useTranslation(['littleCards']);
  return (
    <div ref={ref} className="vc-bs-container">
      <div id="vc-bs-card-bottom-shape">
        <div className="vc-bs-card-bottom-container">
          <LittleCard
            visible={visible}
            id="fast"
            title={t('fast.title')}
            description={t('fast.description')}
          />
          <LittleCard
            visible={visible}
            id="targeted"
            title={t('targeted.title')}
            description={t('targeted.description')}
          />
          <LittleCard
            visible={visible}
            id="efficient"
            title={t('efficient.title')}
            description={t('efficient.description')}
          />
        </div>
      </div>

      <div className="vc-bs-right-container">
        <div className={visible ? 'vc-bs-text animated' : 'vc-bs-text'}>
          <span id="bs-caption">{t('home.subtitle')}</span>
          <span id="bs-title">{t('home.title')}</span>
          <span id="bs-description">{t('home.description')}</span>
        </div>
        <div id="vc-bs-imageContainer">
          <img id="vc-bs-image" src="assets/img/feature-ec-logo.svg" />
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
