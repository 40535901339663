import React from 'react';
import BottomSection from './components/VerticalCard/BottomSection/BottomSection';
import MainSlider from './components/MainSlider';
import WhatWeDoCard from './components/WhatWeDoCard';
import News from './components/News';
import Solutions from './components/Solutions';
import BlueBanner from '../../components/BlueBanner';
import { useTranslation } from 'react-i18next';
import './styles.css';

const Home = () => {
  const { t } = useTranslation(['blueBanner']);
  return (
    <>
      <MainSlider />
      <WhatWeDoCard />
      <BottomSection />
      <div id="home-blue-banner-margin">
        <BlueBanner
          caption={t('option1.caption')}
          title={t('option1.title')}
          buttonLabel={t('option1.button')}
        />
      </div>
      <Solutions />
      <News title={t('news.title')} />
    </>
  );
};

export default Home;
