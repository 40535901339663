import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

const WhatWeDoCard = () => {
  const { t } = useTranslation(['whatWeDo']);

  return (
    <div className="wwd-top-container">
      <div className="wwd-card-container">
        <Grid container spacing={3} className="wwd-title-container">
          <Grid item xs={12}>
            <span className="wwd-title-one">{t('subtitle')}</span>
          </Grid>
          <Grid item xs={12}>
            <span className="wwd-title-two">{t('title')}</span>
          </Grid>
        </Grid>
        <Grid container className="wwd-container">
          <Grid item sm={12} md={3} className="wwd-item-container">
            <img width="75px" src="assets/img/wwd-eye.svg" />
            <span className="wwd-item-title">{t('cards.deepLearning.title')}</span>
            <span className="wwd-item-description">{t('cards.deepLearning.description')} </span>
          </Grid>
          <Grid item xs={1} className="wwd-item-container wwd-arrow">
            <img width="31px" src="assets/img/arrow-right.svg" />
          </Grid>
          <Grid item sm={12} md={3} className="wwd-item-container">
            <img width="75px" src="assets/img/wwd-light.svg" />
            <span className="wwd-item-title">{t('cards.imageAnalysis.title')}</span>
            <span className="wwd-item-description">{t('cards.imageAnalysis.description')}</span>
          </Grid>
          <Grid item xs={1} className="wwd-item-container wwd-arrow">
            <img width="31px" src="assets/img/arrow-right.svg" />
          </Grid>
          <Grid item sm={12} md={3} className="wwd-item-container">
            <img width="75px" src="assets/img/wwd-face.svg" />
            <span className="wwd-item-title">{t('cards.faceRecognition.title')}</span>
            <span className="wwd-item-description">{t('cards.faceRecognition.description')}</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default WhatWeDoCard;
