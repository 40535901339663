import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

const TopSection = () => {
  const { t } = useTranslation(['solutions']);
  return (
    <div className="solutions-container">
      <img id="solutions-logo" src="assets/img/about-us-ts-cut-logo.svg" />
      <img id="solutions-logo-mobile" src="assets/img/about-us-ts-cut-logo-mobile.svg" />
      <div className="solutions-text-container">
        <div className="solutions-text-caption-container">
          <span id="solutions-text-caption">{t('top.caption')}</span>
        </div>
        <div className="solutions-text-description-container">
          <p>{t('top.description')}</p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
