import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en', 'es'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    ns: [
      'aboutUs',
      'blueBanner',
      'contactUs',
      'home',
      'industries',
      'littleCards',
      'news',
      'rest',
      'solutions',
      'whatWeDo',
    ],
    whitelist: languages,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
