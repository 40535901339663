import useMediaQuery from '@material-ui/core/useMediaQuery';
import './styles.css';
const Startup = () => {
  const isTabletLg = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <div id="presentation-container">
      <div id="video">
        <h1>Introduction</h1>
        <video controls width={isTabletLg ? '850px' : '90%'}>
          <source src="assets/videos/wc/presentation_01.mp4" type="video/mp4" />
        </video>
      </div>
      <div id="video">
        <h1>Products & Services</h1>
        <video controls width={isTabletLg ? '850px' : '90%'}>
          <source src="assets/videos/wc/presentation_02.mp4" type="video/mp4" />
        </video>
      </div>
      <div id="video">
        <h1>Skin Up project</h1>
        <video controls width={isTabletLg ? '450px' : '90%'}>
          <source src="assets/videos/wc/presentation_03.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Startup;
