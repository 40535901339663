import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

const ITEMS = [
  {
    img: 'assets/img/wwd-light.svg',
    title: 'cards.deepLearning.title',
    description: 'cards.deepLearning.description',
  },
  {
    img: 'assets/img/wwd-face.svg',
    title: 'cards.faceRecognition.title',
    description: 'cards.faceRecognition.description',
  },
  {
    img: 'assets/img/wwd-eye.svg',
    title: 'cards.imageAnalysis.title',
    description: 'cards.imageAnalysis.description',
  },
  {
    img: 'assets/img/wwd-celphone.svg',
    title: 'cards.metaPrograming.title',
    description: 'cards.metaPrograming.description',
  },
];

const WhatWeDo = () => {
  const { t } = useTranslation(['whatWeDo']);
  return (
    <div className="au-wwd-container">
      <div className="au-wwd-card-container">
        <div className="au-wwd-text-container">
          <div id="au-wwd-caption">
            <span>{t('subtitle')}</span>
          </div>
          <div id="au-wwd-title">
            <span>{t('title')}</span>
          </div>
        </div>
        <Grid container spacing={6}>
          {ITEMS.map((item, index) => (
            <Grid key={index} item sm={12} md={6}>
              <Grid container spacing={3}>
                <Grid item sm={12} md={3}>
                  <img src={item.img} />
                </Grid>
                <Grid item sm={12} md={9}>
                  <p id="au-wwd-item-title">{t(item.title)}</p>
                  <p id="au-wwd-item-description">{t(item.description)}</p>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default WhatWeDo;
