import React from 'react';
import PropTypes from 'prop-types';

const LittleCard = ({ title, description, id }) => {
  return (
    <div className="au-little-card">
      <div className="au-little-card-container">
        <img width="75px" src={`assets/img/${id}.svg`} />
        <span className="wwd-item-title">{title}</span>
        <span className="wwd-item-description">{description}</span>
      </div>
    </div>
  );
};

LittleCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default LittleCard;
