import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './styles.css';

const CardController = ({ index, slides }) => {
  const history = useHistory();

  const handleLink = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  const { t } = useTranslation(['home']);
  const current = slides[index];
  return (
    <div id="main-card-container">
      <h3 className="main-card-title">{current.title}</h3>
      <p className="main-card-content">{current.subtitle}</p>
      <button className="main-card-button" onClick={() => handleLink(current.url)}>
        <a>{t('slider.button')}</a>
      </button>
      <div className="dot_container">
        {slides.map((item, i) => (
          <span key={String(i)} className={i === index ? 'dot active' : 'dot'}></span>
        ))}
      </div>
    </div>
  );
};

CardController.propTypes = {
  index: PropTypes.number,
  slides: PropTypes.array,
  handleClick: PropTypes.func,
};

export default CardController;
