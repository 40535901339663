import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import './styles.css';

const Featured = ({ industries, title, src, id, date }) => {
  const { t } = useTranslation(['news', 'rest', 'industries']);
  const tags = industries.map((ind, index) => (
    <span className="ft-ind-name" key={String(index)}>
      {`${t(`industries:${ind}`)}${industries.length !== index + 1 ? '/' : ''}`}
    </span>
  ));
  return (
    <div id="featured">
      {!!src && <img src={`assets/img/${src}`} id="ft-img" />}
      <Paper elevation={3}>
        <div id="ft-sidecontent">
          <div id="ft-ind-container">{tags}</div>
          <div id="ft-new-title">
            <span>{t(title)}</span>
          </div>
          <div id="ft-new-date">{date}</div>
          <Link id="ft-new-button" to={`/news/${id}`} style={{ textDecoration: 'none' }}>
            {t('rest:words.more')}
          </Link>
        </div>
      </Paper>
    </div>
  );
};
Featured.propTypes = {
  industries: PropTypes.array,
  title: PropTypes.string,
  date: PropTypes.string,
  src: PropTypes.string,
  id: PropTypes.number,
};

export default Featured;
