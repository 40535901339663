import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles.css';

const ShareButton = ({ socialMedia }) => {
  const { t } = useTranslation('rest');
  const urlToShare = encodeURI(window.location.href);
  let path = '';
  let iconSrc = '';

  switch (socialMedia) {
    case 'facebook':
      path = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;
      iconSrc = 'assets/img/facebook-news-icon.svg';
      break;
    case 'linkedin':
      path = `https://www.linkedin.com/sharing/share-offsite/?url=${urlToShare}`;
      iconSrc = 'assets/img/linkedin-news-icon.svg';
      break;
    case 'twitter':
      path = `https://twitter.com/intent/tweet?text=${t('words.twitterMessage')}&url=${urlToShare}`;
      iconSrc = 'assets/img/twitter-news-icon.svg';
      break;
    default:
      path = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;
      iconSrc = 'assets/img/facebook-news-icon.svg';
      break;
  }
  return (
    <a href={path}>
      <img className="sn-social-media-icon" src={iconSrc} />
    </a>
  );
};

export default ShareButton;

ShareButton.propTypes = {
  socialMedia: PropTypes.string.isRequired,
};
