import React from 'react';
import PropTypes from 'prop-types';
import Covid19Svg from './icons/Covid19Svg';
import MicroscopeSvg from './icons/MicroscopeSvg';
import OwlSvg from './icons/OwlSvg';

const Svg = ({ name, color }) => {
  switch (name) {
    case 'owl':
      return <OwlSvg color={color} />;
    case 'microscope':
      return <MicroscopeSvg color={color} />;
    case 'covid19':
      return <Covid19Svg color={color} />;
    default:
      return <OwlSvg color={color} />;
  }
};

export default Svg;

Svg.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
