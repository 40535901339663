import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import { FILTER_BUTTONS } from './helper';
import './index.css';

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: 'Livvic',
        color: '#225CD1',
        fontWeight: 700,
        fontSize: 16,
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #225CD1',
        borderRadius: '50px',
        padding: '0px 10px',
      },
      underline: {
        '&:before': {
          borderBottom: '0px',
        },
        '&:after': {
          borderBottom: '0px',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '0px',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: '#225CD1',
      },
    },
  },
});

const FilterSection = ({ filter, handleChangeFilter }) => {
  const { t } = useTranslation(['rest', 'industries']);

  return (
    <div className="section-cl-filter-container">
      {FILTER_BUTTONS.map((button, index) => (
        <div
          key={index}
          className={`section-cl-filter-button ${
            filter === button.name ? 'section-cl-filter-button-active' : ''
          }`}
          onClick={() => handleChangeFilter(button.name)}
        >
          <span
            className={`section-cl-filter-button-text ${
              filter === button.name ? 'section-cl-filter-button-text-active' : ''
            }`}
          >
            {t(`industries:${button.name}`)}
          </span>
        </div>
      ))}
      <div id="section-cl-filter-button-select">
        <ThemeProvider theme={theme}>
          <Select value={filter} onChange={(e) => handleChangeFilter(e.target.value)}>
            {FILTER_BUTTONS.map((button, index) => (
              <MenuItem key={index} value={button.name}>
                <div className="navbar-language-container">
                  <span className="navbar-span-language">{t(`industries:${button.name}`)}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default FilterSection;

FilterSection.propTypes = {
  filter: PropTypes.string.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
};
