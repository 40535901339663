import React from 'react';
import { Grid } from '@material-ui/core';
import './index.css';
import Accordion from '../../../../components/Accordion';
import { useTranslation } from 'react-i18next';

const OurMethodology = () => {
  const { t } = useTranslation(['aboutUs']);

  const items = [
    {
      title: t('collapsible.item1.title'),
      description: t('collapsible.item1.description'),
    },
    {
      title: t('collapsible.item2.title'),
      description: t('collapsible.item2.description'),
    },
    {
      title: t('collapsible.item3.title'),
      description: t('collapsible.item3.description'),
    },
    {
      title: t('collapsible.item4.title'),
      description: t('collapsible.item4.description'),
    },
  ];

  return (
    <div className="au-om-container">
      <Grid container alignContent="center">
        <Grid item sm={12} md={6}>
          <div className="au-om-accordion-container">
            <Accordion items={items} />
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <div className="au-om-text-container">
            <span id="au-om-title">{t('collapsible.titleUpper')}</span>
            <span id="au-om-title">{t('collapsible.titleLower')}</span>
            <span id="au-om-caption">{t('collapsible.subtitle')}</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurMethodology;
