import { useState, useEffect } from 'react';

const useIntersectionObserver = (ref) => {
  const [visible, setVisible] = useState(false);

  const cb = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(cb, {
      root: null,
      rootMargin: '0px',
      threshold: [0.25],
    });
    const node = ref.current;
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (node) observer.unobserve(node);
    };
  }, [ref]);

  return visible;
};

export default useIntersectionObserver;
