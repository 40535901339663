import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import BlueBanner from 'components/BlueBanner';
import Industries from '../SingleSolution/components/Industries';
import News from 'pages/home/components/News/News';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { news } from 'data';
import './styles.css';
import ShareButton from './ShareButton';

const SingleNew = () => {
  const { t } = useTranslation(['news', 'blueBanner', 'rest']);
  let { id } = useParams();
  const [{ title, content, date, industries, urlVideo }] = news.filter(
    (n) => n.id === parseInt(id)
  );

  console.log(encodeURI(window.location.href));
  return (
    <div>
      <div className="sn-top-container">
        <div className="sn-video-container">
          <div id="sn-video-blue-bg" />
          <video id="sn-video-frame" width="100%" height="650" controls>
            <source src={urlVideo} type="video/mp4" />
          </video>
          <div className="sn-social-media-container">
            <ShareButton socialMedia="facebook" />
            <ShareButton socialMedia="linkedin" />
            <ShareButton socialMedia="twitter" />
          </div>
        </div>
      </div>
      <Grid container spacing={6} className="sn-content-container">
        <Grid item xs={12} md={8}>
          <div className="sn-left-container">
            <span className="sn-left-date">{date}</span>
            <span className="sn-left-title">{t(title)}</span>
            <span className="sn-left-description">{t(content)}</span>
            <span className="sn-left-sm">{t('rest:words.share')}</span>
            <div>
              <ShareButton socialMedia="facebook" />
              <ShareButton socialMedia="linkedin" />
              <ShareButton socialMedia="twitter" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="sn-right-container-menu">
            <div className="sn-menu-divider" />
            <span className="sn-right-title-menu">{t('rest:words.industries')}</span>
            {industries.map((item, index) => (
              <React.Fragment key={index}>
                <Industries name={item} />
                <Divider />
              </React.Fragment>
            ))}
          </div>
          {/* <div className="sn-right-container-menu">
            <div className="sn-menu-divider" />
            <span className="sn-right-title-menu">{t('rest:words.solutions')}</span>
            {industries.map((item, index) => (
               <React.Fragment key={index}>
                <div className="ss-industry">
                  <div className="ss-ind-img">
                    <img src={item.icon} />
                  </div>
                  {item.name}
                </div>
                <Divider />
              </React.Fragment>
            ))}
          </div> */}
        </Grid>
      </Grid>
      <News title={t('news.title.two')} />
      <BlueBanner
        title={t('blueBanner:option2.title')}
        buttonLabel={t('blueBanner:option2.button')}
        titleStyle={{ fontSize: '32px', maxWidth: '400px' }}
      />
    </div>
  );
};

export default SingleNew;
