import React from 'react';
import TopSection from './components/TopSection';
import CardList from './components/CardList';
import BlueBanner from '../../components/BlueBanner';
import { useTranslation } from 'react-i18next';
import News from 'pages/home/components/News/News';

const Solutions = () => {
  const { t } = useTranslation(['blueBanner']);
  return (
    <>
      <TopSection />
      <CardList />
      <BlueBanner
        title={t('option2.title')}
        buttonLabel={t('option2.button')}
        titleStyle={{ fontSize: '32px', maxWidth: '400px' }}
      />
      <News color="light" title={t('news.title')} />
    </>
  );
};

export default Solutions;
