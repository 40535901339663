import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './styles.css';

const Card = ({ url, src, title, description }) => {
  const { t } = useTranslation(['rest']);
  const history = useHistory();

  const handleLink = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <div id="solutions-card-container">
      <span className="icon" id={`${src}`} />
      <h5>{title}</h5>
      <p>{description}</p>
      <a onClick={() => handleLink(url)}>
        {t('words.know')}&nbsp;
        <span>{t('words.more')}</span>
        <span id="solutions-button-arrow" />
      </a>
    </div>
  );
};

Card.propTypes = {
  url: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card;
